<template>
  <div>
    <BaseContainer class="pt-24 pb-16 relative z-20 min-h-screen">
      <BaseHeadingAndText
        heading="Partner widget"
        headingSize="6xl"
        headingColor="cdlpink"
        textSize="xl"
      >
        Our public ethereum donation tracker for your website.
      </BaseHeadingAndText>

      <div class="mt-24">
        <h2 class='mb-8 text-center'>configure your widget</h2>
        <div class="flex flex-row flex-wrap items-center justify-center bg-cdlcream-light p-4 rounded-lg">
          <div class="flex flex-row pr-4">
            <BaseInput
              class=""
              label="your Partner ID"
              placeholder=""
              mode="edit"
              v-model="partnerId"
              @input="onPartnerIdInput"
            />
          </div>

          <div>
            <div class="mt-3 flex flex-row flex-wrap items-center">
              <BaseToggle
                class="px-4 pt-3"
                text="show fiat"
                :initialValue="this.showFiat"
                @input="onShowFiatToggle"
              />
              <BaseToggle
                class="px-4 pt-3"
                text="show latest"
                :initialValue="this.showLatest"
                @input="onShowLatestToggle"
              />
            </div>
            <div class="mt-5 flex flex-row flex-wrap items-center justify-center">
              <BaseTabs label="size" :tabs="sizes" @select="sizeSelected" />
              <BaseTabs
                class="ml-5"
                label="theme"
                :tabs="themes"
                @select="themeSelected"
              />
            </div>
          </div>
        </div>

        <div class="my-20 flex flex-row justify-center">
          <iframe v-bind="iframeProps" :src="src" />
        </div>

        <div class="mt-6 text-center">
          <span>Place this code on your website:</span>
          <p
            class="
              font-mono
              w-full
              bg-cdlcream-light
              rounded-lg
              p-5
              text-sm
              whitespace-pre-wrap
              text-left
            "
          >{{ embedCode }}
          </p>
        </div>
      </div>
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/common/Footer"
export default {
  name: "Widget",
  components: {
    Footer,
  },
  data() {
    return {
      partnerId: "demoPartner",
      theme: null,
      iframeProps: {
        src: null,
        scrolling: "no",
        frameBorder: 0,
        allowTransparency: true,
        allowFullscreen: true,
        style: {
          width: null,
          height: "400px",
        },
        allow: "clipboard-write",
      },
      sizes: [
        {id: "small", name: "small", width: 330, current: false},
        {id: "medium", name: "medium", width: 570, current: false},
        {id: "large", name: "large", width: 750, current: true},
      ],
      themes: [
        {id: "pink", name: "pink", current: false},
        {id: "cream", name: "cream", current: false},
        {id: "clean", name: "clean", current: true},
      ],
      showFiat: true,
      showLatest: true,
    }
  },
  computed: {
    src() {
      return `${process.env.VUE_APP_URI_WIDGET}/?partnerId=${this.partnerId}&theme=${this.theme}&showFiat=${this.showFiat}&showLatest=${this.showLatest}`
    },
    selectedWidth() {
      return this.sizes.find((s) => s.current).width
    },
    embedCode() {
      return `<div id="cuddle-widget" data-partner-id="${this.partnerId}" data-theme="${this.theme}" data-width="${this.selectedWidth}" data-show-fiat="${this.showFiat}" data-show-latest="${this.showLatest}"></div>\n<script async src="${process.env.VUE_APP_URI_WIDGET}/w.js" charset="utf-8"><\/script>`
    },
  },
  mounted() {
    this.autoSetPartnerId()
    this.setTheme("pink")
    this.setSize(750)
    window.addEventListener("message", this.receiveMessage, false)
  },
  methods: {
    autoSetPartnerId() {
      let url = window.location.href
      url = new URL(url)
      let partnerId = url.searchParams.get("partnerId")
      if (partnerId) this.partnerId = partnerId
    },
    sizeSelected(tab) {
      this.setSize(tab.width)
      this.sizes.map((item, index) => {
        this.sizes[index].current = item.id == tab.id
      })
    },
    themeSelected(tab) {
      this.setTheme(tab.id)
      this.themes.map((item, index) => {
        this.themes[index].current = item.id == tab.id
      })
    },
    onPartnerIdInput(v) {
      this.partnerId = v
    },
    onShowLatestToggle(v) {
      this.showLatest = v
    },
    onShowFiatToggle(v) {
      this.showFiat = v
    },
    setSize(width) {
      this.iframeProps.style.width = `${width}px`
    },
    setTheme(theme) {
      this.theme = theme
    },
    receiveMessage(event) {
      if (event && event.data) {
        if (event.data.event) {
          if (event.data.event == "heightChanged") {
            this.iframeProps.style.height = `${event.data.payload.height}px`
          }
        }
      }
    },
  },
}
</script>
